<template>
    <div>
        <el-dialog
            :visible.sync="showDialog"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :close-on-press-escape="true"
            :show-close="false"
            width="500px">
            <span class="cursor-pointer text-2xl absolute top-5 right-10" @click="closeDialog"><i class="icofont-close-line"></i></span>
            <div>
                <h3 class="text-center text-gray-400 text-xl mb-5">
                    {{ title }}
                </h3>
                <p class="text-center text-light text-gray-300 text-base mb-10">請輸入服務商手機</p>
            </div>
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item v-loading="loading" prop="mobile">
                    <div class="flex">
                        <el-select v-model="form.countryCode" class="flex-shrink w-40" placeholder="國碼">
                            <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                        <el-input v-model="form.mobile" class="flex-grow" placeholder="請輸入手機號碼"> </el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div class="flex justify-center">
                <button
                    :disabled="disabled"
                    class="bg-black rounded-full text-white py-2 w-[200px] disabled:bg-gray-100 disabeld:text-white disabled:cursor-not-allowed"
                    @click.prevent="add()">
                    新增
                </button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { phoneOptions } from "@/config/optionsConfig";
export default {
    name: "AddPhoneDialog",
    props: {
        value: {
            type: Boolean,
            require: true,
        },
        title: {
            type: String,
            default: "新增推廣大圖",
        },
        datas: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        // 顯示彈窗
        showDialog: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("input", val);
            },
        },
    },
    data() {
        return {
            form: {
                countryCode: "886",
            },
            rules: {
                mobile: [
                    {
                        required: true,
                        message: "輸入搜尋手機號碼",
                        trigger: "blur",
                        value: "938595567	",
                    },
                    { validator: this.formPhoneSearchUser, trigger: "blur" },
                ],
            },
            // 旋轉動畫
            loading: false,
            // 判斷是否可以點擊新增幹鈕
            disabled: true,
            // api 回傳資訊
            data: {},
            phoneOptions,
        };
    },
    methods: {
        // 使用手機號碼搜尋供給者
        async formPhoneSearchUser(rule, value, callback) {
            // 判斷信箱是否為空值 或 信箱格式是否正確
            if (!this.checkUser()) {
                this.disabled = true;
                return callback(new Error("此服務商已新增"));
            }

            if (value !== "") {
                if (value.length > 0 && value[0] == 0) {
                    this.$set(this.form, "mobile", value.substring(1));
                }
                this.loading = true;
                const { status, data } = await this.$api
                    .UserSearchByPhone(this.form.countryCode + this.form.mobile)
                    .then((res) => {
                        return { status: res.status, data: res.data };
                    })
                    .catch(() => {
                        return { status: 400, data: { message: "err" } };
                    })
                    .finally(() => {
                        this.loading = false;
                    });
                if (status == 200) {
                    this.data = { ...data };
                    if (data.role == 0) {
                        this.disabled = true;
                        return callback(new Error("輸入的搜尋條件無法找到服務商"));
                    }
                    this.disabled = false;
                    return callback();
                } else {
                    this.disabled = true;
                    return callback(new Error("輸入的搜尋條件無法找到服務商"));
                }
            }
        },
        // 關閉彈窗 與關閉searchBar 共用事件
        closeDialog() {
            this.form = {
                countryCode: "886",
            };
            this.showDialog = false;
            this.$refs.form.resetFields();
            this.$emit("close");
        },
        // 新增供給者
        add() {
            this.$emit("getData", this.data.phone);
            this.showDialog = false;
        },
        // 確認是否已新增
        checkUser() {
            let result = true;
            this.datas.forEach((i) => {
                if (i.phone == this.form.countryCode + this.form.mobile) {
                    result = false;
                }
            });
            return result;
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
    @apply border-b border-gray-200 border-0 rounded-none;
}
</style>
